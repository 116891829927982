import axios from "./config";

export default {
  getPage: async function () {
    try {
      const response = axios.get("/pages");
      return response;
    } catch (error) {
      console.log(error);
    }
  },

  makeListServiceQueryParams: function ({
    search = "",
    role = "",
    project_id = "",
    is_active = "",
  }) {
    const params = new URLSearchParams();
    if (search) {
      params.append("search", search);
    }
    if (role) {
      params.append("role", role);
    }
    if (project_id) {
      params.append("project_id", project_id);
    }
    if (is_active) {
      const value = is_active === "1" ? true : false;
      params.append("is_active", value);
    }
    return params;
  },
};

<template>
  <div class="select-container-block">
    <label class="label-input-select" :for="name">{{ label }}</label>
    <div ref="dropdownRef" class="custom-select">
      <div
        class="selected-value"
        @click.self="toggleDropdown"
        :class="{ error: !!errorMessage }"
      >
      {{ selectedLabel }}
        <i
          class="fa"
          :class="{
            'fa-angle-down': !dropdownOpen,
            'fa-angle-up': dropdownOpen,
          }"
        ></i>
      </div>
      <div v-if="dropdownOpen" class="dropdown">
        <input
          type="text"
          v-model="searchQuery"
          @input="filterOptions"
          placeholder="Search..."
          class="search-input"
        />
        <ul class="options-list">
          <li
            v-for="option in filteredOptions"
            :key="option[optionValue]"
            @click="selectOption(option)"
            class="option-item"
          >
            {{ option[optionLabel] }}
          </li>
        </ul>
      </div>
    </div>
    <small v-if="errorMessage" :class="{ 'has-error': !!errorMessage }">
      {{ errorMessage }}
    </small>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { useField } from "vee-validate";
import * as yup from "yup";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    default: () => [],
  },
  optionValue: {
    type: String,
    required: true,
  },
  optionLabel: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "Select a Project",
  },

});

const emit = defineEmits(["update:modelValue", "change", "blur"]);

const searchQuery = ref("");
const filteredOptions = ref([...props.options]);
const dropdownOpen = ref(false);
const dropdownRef = ref(null);

const selectedLabel = computed(() => {
  const selectedOption = props.options.find(
    (option) => option[props.optionValue] === inputValue.value
  );
  return selectedOption
    ? selectedOption[props.optionLabel]
    : props.placeholder;
});

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
};

const selectOption = (option) => {
  inputValue.value = option[props.optionValue];
  emit("update:modelValue", inputValue.value);
  emit("change", inputValue.value);
  dropdownOpen.value = false;
};

const filterOptions = () => {
  const query = searchQuery.value.toLowerCase();
  filteredOptions.value = props.options.filter((option) =>
    option[props.optionLabel].toLowerCase().includes(query)
  );
};

watch(
  () => props.options,
  (newOptions) => {
    filteredOptions.value = [...newOptions];
  }
);

// Define validation schema
const schema = yup.string().required("This field is required");

// Use vee-validate
const { value: inputValue, errorMessage } = useField(props.name, schema, {
  initialValue: props.value,
});

const handleClickOutside = (event) => {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    dropdownOpen.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

const emitBlur = () => {
  emit("blur");
};
</script>

<style scoped>
.select-container-block {
  position: relative;
  width: 100%;
}

.label-input-select {
  display: block;
  margin-bottom: 4px;
}

.custom-select {
  position: relative;
  width: 100%;
}

.selected-value {
  padding: 8px;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

selected-value span {
  verflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selected-value.error {
  border-color: var(--error-color);
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  background: white;
  z-index: 1000;
}

.search-input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
}

.options-list {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
}

.option-item {
  padding: 8px;
  cursor: pointer;
}

.option-item:hover {
  background-color: #f0f0f0;
}

.has-error {
  color: var(--error-color);
}

.has-error-select {
  border-color: var(--error-color);
}
</style>
